.cardContainer {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(180deg, #3d3d3d -10.31%, rgba(23, 23, 23, 0) 99.12%);
  border-radius: 10px;
  height: 170px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  width: 290px;
  padding: 12px;
  padding-bottom: 30px;
  margin: 20px 0px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .textOutline {
      border-color: #b9b9b9;
    }
    .textRoot {
      color: #f1f1f1;
    }
    .textInput {
      padding: 4px 6px;
      font-size: 14px;
      line-height: 21px;
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #f1f1f1;
      width: 75%;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
    .icon {
      height: 20px;
      width: 20px;
      margin: 0 4px;
    }
  }
  .subText {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #b9b9b9;
    font-size: 900;
  }
  .bottomWrapper {
    font-size: 12px;
    line-height: 18px;
    color: #b9b9b9;
    position: absolute;
    bottom: 16px;
    width: 100%;
    .bottomIcon {
      height: 12px;
      width: 20px;
      margin-right: 6px;
    }
    .footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .duration {
        margin-right: 10px;
      }
      .loader {
        color: #b9b9b9;
      }
    }
  }
}
.popoverRoot {
  margin: 4px 7px 0 7px;
}
.popoverPaper {
  overflow: visible;
  margin-top: 7px;
  &::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #171717;
    content: '';
    position: absolute;
    top: -9px;
    right: 12px;
  }
}
.popover {
  width: 120px;
  padding: 4px 6px;
  background: #171717;
  box-shadow: 0px 4px 4px rgba(65, 65, 65, 0.25);

  .menuOptions {
    font-size: 14px;
    line-height: 21px;
    color: #b9b9b9;
  }
}

.paper {
  min-width: 500px;
}

.dialogBody {
  background: #2b2b2b;
  .dialogBodyContent {
    margin-bottom: 20px;
    .textWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .delete {
        width: 60px;
        height: 80px;
      }
      .text {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
      }
      .subText {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        opacity: 0.4;
        margin-top: 8px;
      }
    }
  }
  .dialogActions {
    background: #222222;
    justify-content: center;
    .button {
      padding: 2px 28px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
}

.processWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background: #2e2e2e;
  border-radius: 10px;
  height: 170px;
  width: 100%;
  padding: 12px;
  margin: 20px 0px;
  .processText {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #f1f1f1;
    margin-top: 6px;
  }
  .processSubtext {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #f1f1f1;
    margin-top: 4px;
  }
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background: #2e2e2e;
  border-radius: 10px;
  height: 170px;
  width: 100%;
  padding: 12px;
  margin: 20px 0px;
  .icons {
    align-self: flex-end;
  }
  .deleteIcon {
    align-self: flex-end;
    margin-bottom: 24px;
  }
  .errorIcon {
    width: 20px;
    height: 20px;
  }
  .errorText {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #f1f1f1;
    margin-top: 6px;
  }
  .errorSubText {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #eb756f;
    margin-top: 6px;
    .warningIcon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
      padding-bottom: 3px;
    }
  }
}

.circularProgress {
  display: inline-flex;
  position: absolute;
  left: 0;
  .percentageLoader {
    color: #3860f6;
  }
  .loaderTextWrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .percentageText {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #f1f1f1;
    }
  }
}
