.paginationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 14px 10px;
  z-index: 10;
  @apply rounded-3xl bg-[#242424]/90;
}

.inputSubmit.inputSubmit {
  color: white;
  display: none;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}

.inputRoot {
  border: 1px solid rgba(39, 39, 39, 0.5);
  border-radius: 5px;
  width: 60px;
  padding: 5px;

  &:global(.Mui-error) {
    border: 1px solid rgba(229, 0, 0, 0.899);
  }
}

.textWrapper {
  display: flex;
  align-items: center;
  .navigation {
    display: inline-block;
    padding: 3px 0;
  }

  &:focus-within .inputSubmit {
    display: block;

    &:disabled {
      display: none;
    }
  }

  .textRoot {
    display: inline;
  }
}

.helperText {
  font-size: 12px;
}

.input {
  padding: 5px 5px;
  font-size: 12px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text {
  white-space: nowrap;
  margin-right: 30px;
}

.paginationSkeleton {
  display: flex;
  justify-content: space-between;
}

.paginationEmpty {
  display: flex;
  justify-content: center;
}
