.titleHeading {
  margin-left: 0px;
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: rgb(255 255 255);
}
@media (min-width: 1440px) {
  .titleHeading {
    margin-left: 62px;
  }
  .titleHeading {
    justify-content: flex-start;
  }
}
.pagination {
  position: fixed;
  right: 1rem;
  bottom: 0rem;
  ul li button {
    color: #979da7;
    background-color: #1e2024;
  }
}
.inputBar.inputBar {
  height: 40px;
}
.projectList {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: grid;
  overflow-x: scroll;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: center;
  row-gap: 1.5rem;
}
@media (min-width: 1500px) {
  .projectList {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
@media (min-width: 1040px) {
  .projectList {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 740px) {
  .projectList {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1440px) {
  .projectList {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
